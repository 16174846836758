import React from 'react';
import './styles/Skills.css';

const Skills = () => {
    return (
        <section className="skills">
            <h2>Skills</h2>
            <ul>
                <li>Front-end: JavaScript, HTML5, CSS3, TypeScript, React/Redux/Hook, JSON, SASS/SCSS, Webpack, Jest, TDD, Bootstrap, Material UI, Restful API, WPA, GraphQL</li>
                <li>Back-end: Java, Spring boot, Node, NextJS, Python, Express, SSR, SSG, AMP, RestAPI, Express</li>
                <li>Cloud: Docker, Bash/shell, Spring Cloud, AWS, Heroku, Firebase, CI/CD, Git, Docker, Kubernetes, Jira</li>
                <li>Database: MySQL, MongoDB, Redis, Memcached, Rocket MQ, Kafka</li>
                <li>Architecture & Methodology: Microservices, Object-Oriented Programming, MVC, Caching, Design Patterns, SDLC, SOLID, Relational Database, Cloud Computing</li>
                <li>Project Management: SDLC, Agile, Kanban, JIRA, Scrum, Kanban, GitHub, GitLab, Git Bash</li>
                <li>Additional skills: OAuth, JWT, Axios, Passport, Payment Gateway, Firebase, AWS, SEO, i18next internationalization, Team Player</li>
            </ul>
        </section>
    );
}

export default Skills;
