import React from 'react';
import './styles/Intro.css';

const Intro = () => {
    return (
        <section className="intro">
            <p>Back in 2018, during my internship at China Everbright Bank, I delved into the world of front-end development and discovered my passion for coding and web development. Fast-forward to today, I’ve had the privilege of building software for financial institutions and consulting firms.

                My main focus these days is as a Full Stack Developer at TopInfoDev IT Consulting, where I create dynamic and responsive web applications. I thrive at the intersection of design and engineering, crafting solutions that are both aesthetically pleasing and robust under the hood. I have extensive experience with modern JS frameworks like React and Angular, and I'm well-versed in back-end technologies such as Java and Node.js.

                In my free time, I enjoy exploring new technologies, enhancing my skills through continuous learning, and contributing to open-source projects. When I’m not at the computer, you can find me reading, spending time with friends and family, or exploring the outdoors.</p>
        </section>
    );
}

export default Intro;
