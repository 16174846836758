import React from 'react';
import './styles/Education.css';

const Education = () => {
    return (
        <section id="education" className="education">
            <h2>Education</h2>
            <div className="school">
                <h3>McMaster University, Hamilton, ON, Canada</h3>
                <p>Bachelor of Commerce (Honors)</p>
                <p>SEP 2019 – JUN 2022</p>
            </div>
        </section>
    );
}

export default Education;
