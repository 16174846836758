import React from 'react';
import Header from './components/Header';
import Home from './components/pages/Home';
import Skills from './components/pages/Skills';
import Experience from './components/pages/Experience';
import Education from './components/pages/Education';
import Contact from './components/pages/Contact';
import './components/pages/styles/App.css';
import Intro from "./components/pages/Intro";

const App = () => {
    return (
        <div className="app">
            <aside className="sidebar">
                <Home />
                <Header />
            </aside>
            <main className="content">
                <section id="intro">
                    <Intro/>
                </section>
                <section id="skills">
                    <Skills/>
                </section>
                <section id="experience">
                    <Experience/>
                </section>
                <section id="education">
                    <Education/>
                </section>
                <section id="contact">
                    <Contact/>
                </section>
            </main>
        </div>
    );
}

export default App;
