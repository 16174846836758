import React, { useState } from 'react';
import './styles/Experience.css';

const jobs = [
    {
        company: 'TopInfoDev IT Consulting',
        title: 'Full Stack Developer',
        location: 'Toronto, ON',
        date: 'June 2022 - Present',
        responsibilities: [
            'Creating a Learning Management platform SPA by using React, React-Redux, Redux-Form, with (Express + NodeJS) as REST API backend to talk to database (MySQL)',
            'Developed data driven dynamic applications for rendering full accessible user interfaces',
            'Followed responsive and mobile first rules to design layout and components',
            'Created various components and using React Hook and universal store using Redux',
            'Demonstrated graphs, tables using third party libraries such as React Hook Form',
            'Integrated online payment channel of PayPal Restful API',
            'Write the model, data migration, and seed data by using TypeORM and MySQL',
            'Designed and implemented the system’s routes and Restful APIs',
            'Implemented the RBAC (Role based access control), supported role and permission assignment and access',
            'Research, design, evaluate and implement the cross-platform deployment solutions',
            'Optimize the application for maximum performance and scalability',
            'Communicate with back-end such as search, get, post etc. using Fetch and Axios'
        ]
    },
    {
        company: 'China Everbright Bank',
        title: 'Front-end Developer (Intern)',
        location: 'Fuzhou, China',
        date: 'July 2018 - August 2018',
        responsibilities: [
            'Wrote unit tests for the High Availability Counter using JUnit, covering the whole transaction link of SPOT',
            'Developed the SDK for the platform’s API and reduced the API call response time by 20% using the LRU',
            'Implemented the Broker Demo Project for sales pitch using Spring Boot and Spring Security',
            'Updating and maintaining the official website portal with NextJS and ReactJS'
        ]
    }
];

const Experience = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <section id="experience" className="experience">
            <h2>Experience</h2>
            <div className="tabs">
                <ul className="tab-list">
                    {jobs.map((job, index) => (
                        <li key={index} className={activeTab === index ? 'active' : ''} onClick={() => setActiveTab(index)}>
                            {job.company}
                        </li>
                    ))}
                </ul>
                <div className="tab-content">
                    {jobs.map((job, index) => (
                        <div key={index} className={`tab-panel ${activeTab === index ? 'active' : ''}`}>
                            <h3>{job.title} <span>@ {job.company}</span></h3>
                            <p className="location">{job.location}</p>
                            <p className="date">{job.date}</p>
                            <ul>
                                {job.responsibilities.map((responsibility, i) => (
                                    <li key={i}>{responsibility}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Experience;
