import React from 'react';
import './styles/Home.css';

const Home = () => {
    return (
        <section className="home">
            <div className="intro">
                <h3>Hi, I'm </h3>
                <h1>Toby Jiang</h1>
                <h3>Full Stack Developer </h3>
            </div>
            <div className="contact-info">
                <p>1-647-517-4999 │ tobyjiang0221@gmail.com</p>
            </div>
        </section>
    );
}

export default Home;
